<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50 d-flex">
        <h5 class="d-inline-block">{{ $t('filters.title') }}</h5>
        <div>
          <a v-if="fileExport" class="d-inline-block mr-1" v-b-tooltip :title="$t('operations.export')"
             @click="$emit('export')"
          ><i class="fa-regular fa-file-excel"></i></a>
          <a v-if="fileImport" class="d-inline-block mr-1" v-b-tooltip :title="$t('operations.import')"
             @click="$emit('import')"
          ><i class="fas fa-file-upload"></i></a>
          <a id="load-query-bottom" class="d-inline-block mr-1" v-b-tooltip :title="$t('operations.load')"><i
              class="fa-solid fa-upload"
          ></i></a>
          <a id="save-query-bottom" class="d-inline-block mr-1" v-b-tooltip :title="$t('operations.save')"><i
              class="fa-regular fa-floppy-disk"
          ></i></a>
          <a id="reset-query-bottom" class="d-inline-block mr-1" v-b-tooltip :title="$t('operations.reset')"
             @click="resetFields"
          ><i class="fa-regular fa-x"></i></a>
          <a class="d-inline-block" @click="refreshTables"><i class="fa-solid fa-rotate-right"></i></a>
        </div>
      </b-card-header>
      <b-card-body>
        <b-row v-if="fields != null && fields.length > 0">
          <b-col cols="12" md="3" lg="3" v-for="field in _fields" v-bind:key="field.key"
                 :class="{ 'pr-2': field.type === 'user', 'mr-0': field.type !== 'user' }"
          >
            <label v-if="field.type !=='checkbox' && field.type !=='checkboxGroup'">{{
                $t(`filters.${field.label}`)
              }}</label>
            <template v-if="field.type==='input'">
              <b-form-input size="sm" v-model="filter[field.key]" :debounce="filter.debounce" class="d-inline-block"/>
            </template>
            <template v-else-if="field.type==='select'">
              <v-select
                  class="small"
                  v-model="filter[field.key]"
                  :options="field.props.options"
                  :label="field.props.label"
                  :reduce="field.props.reduce"
                  :clearable="field.props.clearable || true"
                  :multiple="field.props.multiple || false"
              />
            </template>
            <template v-else-if="field.type==='date'">
              <calendar-picker class="small" v-model="filter[field.key]" :label="$t(`filters.${field.label}`)"/>
            </template>
            <template v-else-if="field.type==='dateRange'">
              <calendar-picker-range class="small" v-model="filter[field.key]" :label="$t(`filters.${field.label}`)"
                                     v-bind="field.props"
              />
            </template>
            <template v-else-if="field.type==='tags'">
              <b-form-tags v-model="filter[field.key]" v-bind="field.props"/>
            </template>
            <template v-else-if="field.type==='checkbox'">
              <b-checkbox style="margin-top: 25px" v-model="filter[field.key]" v-bind="field.props">
                <template v-if="field.noLabel !== false">{{ $t(`filters.${field.label}`) }}</template>
              </b-checkbox>
            </template>
            <template v-else-if="field.type==='dateTime'">
              <b-form-input class="d-inline-block" size="sm" type="date"></b-form-input>
            </template>
            <template v-else-if="field.type==='number'">
              <b-form-input v-model="filter[field.key]" v-bind="field.props" class="d-inline-block" size="sm"
                            type="number"
              ></b-form-input>
            </template>
            <template v-else-if="field.type==='checkboxGroup'">
              <template v-for="f in field.fields">
                <b-checkbox inline style="margin-top: 25px" v-model="filter[f.key]" v-bind="f.props" v-bind:key="f.key">
                  <template v-if="f.noLabel !== false">{{ $t(`filters.${f.label}`) }}</template>
                </b-checkbox>
              </template>
            </template>
            <!--Custom select boxes-->
            <template v-else-if="field.type==='department'">
              <departments-select-box class="small" v-model="filter[field.key]" v-bind:select-props="field.props"
                                      v-bind:selected-business-unit="filter[organizationMap.bu]"
              />
            </template>
            <template v-else-if="field.type==='bu'">
              <business-unit-select-box class="small" v-model="filter[field.key]" v-bind:select-props="field.props"
                                        v-bind:selected-organization="filter[organizationMap.org]"
                                        v-bind:selected-department="filter[organizationMap.dep]"
              />
            </template>
            <template v-else-if="field.type==='org'">
              <organization-select-box class="small" v-model="filter[field.key]" v-bind:select-props="field.props"/>
            </template>
            <template v-else-if="field.type==='affiliate'">
              <affiliate-select-box class="small" v-model="filter[field.key]" v-bind:select-props="field.props"/>
            </template>
            <template v-else-if="field.type==='smtpProvider'">
              <smtp-provider-select-box class="small" v-model="filter[field.key]" v-bind:select-props="field.props"/>
            </template>
            <template v-else-if="field.type==='activity'">
              <activity-select-box class="small" v-model="filter[field.key]" v-bind:select-props="field.props"/>
            </template>
            <template v-else-if="field.type==='monetaryStatus'">
              <monetary-status-select-box class="small" v-model="filter[field.key]" v-bind:select-props="field.props"/>
            </template>
            <template v-else-if="field.type==='monetaryAction'">
              <monetary-action-select-box class="small" v-model="filter[field.key]" v-bind:select-props="field.props"/>
            </template>
            <template v-else-if="field.type==='monetaryRealType'">
              <monetary-action-real-type-select-box class="small" v-model="filter[field.key]"
                                                    v-bind:select-props="field.props"
              />
            </template>
            <template v-else-if="field.type==='user'">
              <user-select-box class="small" v-model="filter[field.key]" v-bind:select-props="field.props"/>
            </template>
            <template v-else-if="field.type==='clientId'">
              <client-picker class="small" v-model="filter[field.key]" v-bind:select-props="field.props"/>
            </template>
            <!--End of custom select boxes-->
          </b-col>
          <b-col cols="12" v-if="search">
            <label>{{ $t(`filters.search`) }}</label>
            <b-form-input size="sm" v-model="filter.search" :debounce="debounce" class="d-inline-block"/>
          </b-col>
        </b-row>
        <div class="text-center position-absolute d-block w-100" style="top:50%" v-else>
          <b-spinner>{{ $t('operations.loading') }}</b-spinner>
        </div>
      </b-card-body>
    </b-card>
    <!-- Save query popover -->
    <b-popover target="save-query-bottom" placement="bottomleft" triggers="click" @show="closeAllPopovers">
      <div style="width: 350px">
        <p class="text-center text-muted" v-html="$t('filters.card.saveFilters')"></p>
        <b-input-group>
          <b-form-input v-model="popover.saveQuery.name" size="sm"></b-form-input>
          <b-input-group-append>
            <b-button size="sm" variant="outline-success" @click="saveQuery">{{ $t('operations.save') }}</b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </b-popover>

    <b-popover target="load-query-bottom" placement="bottomleft" triggers="click" @show="closeAllPopovers"
               @shown="loadAllQueries"
    >
      <div class="position-relative" style="width: 360px">
        <p class="text-center text-muted" v-html="$t('filters.card.loadFilters')"></p>
        <div>
          <v-select
              v-model="popover.loadQuery.selectedQueryIndex"
              :clearable="false"
              label="name"
              :options="popover.loadQuery.queries"
              :reduce="option => option.index"
          />
        </div>

        <b-row no-gutters style="width: 100%; margin-top: 5px">
          <b-col cols="6">
            <b-button class="d-inline-block" size="sm" variant="outline-danger" @click="deleteQuery"
                      :disabled="popover.loadQuery.selectedQueryIndex === null"
            >{{ $t('operations.delete') }}
            </b-button>
          </b-col>
          <b-col cols="6" class="text-right">
            <b-button class="d-inline-block" size="sm" variant="outline-success" @click="loadQuery"
                      :disabled="popover.loadQuery.selectedQueryIndex === null"
            >{{ $t('operations.load') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-popover>
  </div>
</template>

<script>
import { parseJson } from '@/model/Utils';
import OrganizationSelectBox from '@/components/widget/select/OrganizationSelectBox';
import BusinessUnitSelectBox from '@/components/widget/select/BusinessUnitSelectBox';
import CalendarPicker from '@/components/widget/picker/calendarPicker';
import CalendarPickerRange from '@/components/widget/picker/calendarPickerRange';
import UserSelectBox from '@/components/widget/select/UserSelectBox';
import mixinPopover from '@/components/mixin/mixinPopover';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import MonetaryStatusSelectBox from '@/components/widget/select/MonetaryStatusSelectBox';
import MonetaryActionSelectBox from '@/components/widget/select/MonetaryActionSelectBox';
import DepartmentsSelectBox from '@/components/widget/select/DepartmentsSelectBox';
import AffiliateSelectBox from '@/components/widget/select/AffiliateSelectBox.vue';
import SmtpProviderSelectBox from '@/components/widget/select/SmtpProviderSelectBox.vue';
import ActivitySelectBox from '@/components/widget/select/ActivitySelectBox.vue';
import ClientPicker from '@/components/widget/picker/clientPicker';
import MonetaryActionRealTypeSelectBox from '@/components/widget/select/type/monetaryActionRealTypeSelectBox';

export default {
  name: 'FilterCard',
  mixins: [mixinNotifications, mixinPopover],
  components: {
    ClientPicker,
    ActivitySelectBox,
    DepartmentsSelectBox,
    MonetaryStatusSelectBox,
    MonetaryActionSelectBox,
    MonetaryActionRealTypeSelectBox,
    SmtpProviderSelectBox,
    CalendarPickerRange,
    CalendarPicker,
    BusinessUnitSelectBox,
    OrganizationSelectBox,
    UserSelectBox,
    AffiliateSelectBox
  },
  data: () => ({
    sessionKey: null,
    popover: {
      saveQuery: {
        name: '',
        error: ''
      },
      loadQuery: {
        queries: [],
        selectedQueryIndex: null,
      }
    }
  }),
  props: {
    value: {
      required: true,
    },
    loaded: {
      type: Boolean
    },
    fields: {
      required: true,
    },
    debounce: {
      default: () => 1500,
      type: Number
    },
    search: {
      default: () => false,
      type: Boolean
    },
    hiddenFields: {
      required: false,
      default: () => ({}),
      type: Object
    },
    fileExport: {
      default: () => false,
      type: Boolean
    },
    dashboard: {
      default: () => false,
      type: Boolean
    },
    fileImport: {
      default: () => false,
      type: Boolean
    }
  },
  watch: {
    value: {
      deep: true,
      handler(v) {
        sessionStorage.setItem(this.sessionKey, JSON.stringify(v));
      }
    }
  },
  created() {
    if (this.$route.meta.pageKey) {
      this.sessionKey = `${this.$route.name}::${this.$route.meta.pageKey}-filters`;
    } else {
      this.sessionKey = `${this.$route.name}-filters`;
    }
    this.initCache();
    this.$emit('loaded', true);
  },
  computed: {
    _allFields() {
      let $this = this;
      return this.fields.map(field => {
        if (field.type !== 'checkboxGroup') {
          return {
            key: field.key,
            label: field.label || field.key,
            type: field.type || 'input',
            debounce: field.debounce || $this.debounce || 1500,
            props: field.props,
            dateRange: field.dateRange,
            active: $this.hiddenFields == null || $this.hiddenFields[field.key] !== true
          };
        } else {
          return {
            type: field.type,
            fields: field.fields.map(f => {
              return {
                key: f.key,
                label: f.label || f.key,
                debounce: f.debounce || $this.debounce || 1500,
                props: f.props,
                dateRange: f.dateRange,
              };
            }),
            active: $this.hiddenFields == null || $this.hiddenFields[field.key] !== true,
          };
        }
      });
    },
    _fields() {
      return this._allFields.filter(field => field.active);
    },
    filter: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    },
    organizationMap() {
      let $this = this;
      let map = {
        org: null,
        bu: null,
        dep: null
      };
      if (!Array.isArray(this.fields)) {
        return map;
      }
      this._allFields.forEach(field => {
        if (field.type === 'org') {
          map.org = field.key;
        }
        if (field.type === 'bu') {
          map.bu = field.key;
        }
        if (field.type === 'department') {
          map.dep = field.key;
        }
      });
      return map;
    }
  },
  methods: {
    initCache() {
      this.filter = parseJson(sessionStorage.getItem(this.sessionKey), this.filter);
    },
    refreshTables() {
      if (this.dashboard) {
        this.$root.$emit('dashboard::refresh');
      } else {
        this.$root.$emit('system::refresh-tables');
      }
    },
    //Save query popover
    saveQuery() {
      let filter = { ...this.filter };
      delete filter.lastUpdate;
      let queries = parseJson(localStorage.getItem(`${this.sessionKey}-filter-queries`), []);
      queries.push({
        name: this.popover.saveQuery.name,
        query: filter
      });
      localStorage.setItem(`${this.sessionKey}-filter-queries`, JSON.stringify(queries));
      this.popover.saveQuery.name = '';
      this.popover.saveQuery.error = '';
      this.$root.$emit('bv::hide::popover');
      this.showSuccessNotification(this.$t('filters.saveQuerySystem.querySaved'));
    },
    loadAllQueries() {
      let queries = parseJson(localStorage.getItem(`${this.sessionKey}-filter-queries`), []);
      queries = queries.map((q, index) => Object.assign(q, { index }));
      this.popover.loadQuery.queries = queries;
    },
    loadQuery() {
      if (this.popover.loadQuery.selectedQueryIndex == null || this.popover.loadQuery.selectedQueryIndex < 0) return;
      let $this = this;
      let lastUpdate = this.filter.lastUpdate;
      let query = this.popover.loadQuery.queries[this.popover.loadQuery.selectedQueryIndex].query;
      this.filter = {
        ...$this.filter, ...query,
        lastUpdate
      };
      this.forceUpdate();
      this.$root.$emit('bv::hide::popover');
    },
    deleteQuery() {
      if (this.popover.loadQuery.selectedQueryIndex == null || this.popover.loadQuery.selectedQueryIndex < 0) return;
      let queries = this.popover.loadQuery.queries;
      queries.splice(this.popover.loadQuery.selectedQueryIndex, 1);
      localStorage.setItem('suit-queries', JSON.stringify(queries));
      this.popover.loadQuery.selectedQueryIndex = null;
      this.$root.$emit('bv::hide::popover');
      this.showSuccessNotification(this.$t('filters.card.queryDeleted'));
    },
    resetFields() {
      this.filter = {};
    },
  }
};
</script>

<style>

</style>
