<template>
  <v-select
      v-model="model"
      :options="getAllActivities"
      v-bind="selectProps"
      label="label"
      :reduce="(activity) => activity.value"
  />
</template>

<script>
export default {
  name: 'ActivitySelectBox',
  data(){
    return {
      getAllActivities: [
        {label: 'Retrieve', value: "RETRIEVE"},
        {label: 'Login', value: "Login"},
        {label: 'Hierarchy', value: "Hierarchy"},
        {label: 'Get all', value: "Get All"},
        {label: 'Update', value: "UPDATE"},
        {label: 'Update partly', value: "UPDATE_PART"},
        {label: 'Delete', value: "DELETE"},
        {label: 'Duplicate', value: "DUPLICATE"},
      ]
    }
  },
  props: {
    value: {
      required: true,
    },
    selectProps:{
      default: () => ({})
    },
    disabled:{
      type: Boolean,
      default: () => false
    },
    clearable:{
      type: Boolean,
      default: () => false
    },
    multiple:{
      type: Boolean,
      default: () => false
    },
  },
  computed:{
    model:{
      get(){
        return this.value;
      },
      set(value){
        this.$emit('input',value);
      }
    },
    _selectProps(){
      let props = {...this.$props, ...this.selectProps}
      delete props.value
      delete props.options
      delete props.label
      delete props.reduce
      return props
    }
  }
}
</script>

<style scoped>

</style>
