<template>
  <v-select
      v-model="model"
      :options="$store.getters['data/allSmtpProviders']"
      v-bind="selectProps"
  />
</template>

<script>
export default {
  name: 'SmtpProviderSelectBox',
  props: {
    value: {
      required: true,
    },
    selectProps:{
      default: () => ({})
    },
    disabled:{
      type: Boolean,
      default: () => false
    },
    clearable:{
      type: Boolean,
      default: () => false
    },
    multiple:{
      type: Boolean,
      default: () => false
    },
  },
  computed:{
    model:{
      get(){
        return this.value;
      },
      set(value){
        this.$emit('input',value);
      }
    },
    _selectProps(){
      let props = {...this.$props, ...this.selectProps}
      delete props.value
      delete props.options
      delete props.label
      delete props.reduce
      return props
    }
  }
}
</script>

<style scoped>

</style>
