<template>
  <v-select
      v-model="model"
      :options="options"
      label="label"
      :reduce="(dp) => dp.name"
      v-bind="selectProps"
  />
</template>

<script>
export default {
  name: 'DepartmentsSelectBox',
  props: {
    value: {
      required: true,
    },
    selectedBusinessUnit: {
      required: false,
      default: ()=> []
    },
    selectProps:{
      default: () => ({})
    },
    disabled:{
      type: Boolean,
      default: () => false
    },
    clearable:{
      type: Boolean,
      default: () => false
    },
    multiple:{
      type: Boolean,
      default: () => false
    },
  },
  computed:{
    options(){
      let dpts = this.$t('businessUnitDepartments');
      return Object.keys(dpts).map((key) => {
        return {
          name: key,
          label: this.$t(`businessUnitDepartments.${key}`)
        }
      });
    },
    model:{
      get(){
        return this.value;
      },
      set(value){
        this.$emit('input',value);
      }
    },
    _selectProps(){
      let props = {...this.$props, ...this.selectProps}
      delete props.value
      delete props.options
      delete props.label
      delete props.reduce
      return props
    }
  }
}
</script>

<style scoped>

</style>
